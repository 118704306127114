import React from "react"
import { Link } from "gatsby"

const Breadcrumb = props => {
  function isLast(index) {
    return index === props.crumbs.length - 1
  }
  //console.log("BreadCRumbs - props:", props.crumbs)
  return (
    <div className="uk-container uk-margin-top">
      <ul className="uk-breadcrumb">
        {props.crumbs.map((crumb, index) => {
          const { title, slug } = crumb
          const disabled = isLast(index) ? "uk-disabled" : ""

          return (
            <li key={index}>
              <Link to={slug} alt={title} className={`${disabled}`}>
                {title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Breadcrumb
