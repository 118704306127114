import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {
  Experience,
  Values,
  Competitive,
  TitleV2,
  Delivery,
  Satisfaction,
} from "../../components"

const WhyAdudev = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiSatisfaction {
            satisfactionDetails {
              id
              reason
              title
              description
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      placeholder: BLURRED
                      width: 1000
                      height: 650
                    )
                  }
                }
              }
            }
            satisfactionColumn {
              id
              title
              description
              slug
            }
          }
        }
      `}
      render={data => {
        return (
          <section id="content" className="uk-section uk-section-default">
            <TitleV2 title="Why Choose AduDev?" styleClass="underline" />
            <div className="uk-container">
              <div className="uk-section uk-section-small  uk-padding-remove-top uk-margin-top">
                <ul
                  className="uk-subnav orange-subnav uk-subnav-pill uk-flex uk-flex-center"
                  data-uk-switcher="connect: .uk-switcher; animation: uk-animation-fade"
                >
                  <li>
                    <a className="uk-border-pill " href="/">
                      Experience
                    </a>
                  </li>
                  <li>
                    <a className="uk-border-pill" href="/">
                      Values
                    </a>
                  </li>
                  <li>
                    <a className="uk-border-pill" href="/">
                      Competitive
                    </a>
                  </li>
                  <li>
                    <a className="uk-border-pill" href="/">
                      Delivery
                    </a>
                  </li>
                  <li>
                    <a className="uk-border-pill" href="/">
                      Satisfaction
                    </a>
                  </li>
                </ul>
              </div>

              <ul className="uk-switcher uk-margin">
                <li>
                  <Experience />
                </li>
                <li>
                  <Values />
                </li>
                <li>
                  <Competitive />
                </li>
                <li>
                  <Delivery />
                </li>
                <li>
                  <Satisfaction />
                </li>
              </ul>
            </div>
          </section>
        )
      }}
    />
  )
}

export default WhyAdudev
