import React from "react"
import { FooterLinks, SocialLinks } from "../../../components"

const Footer = ({ detail }) => {
  //console.log("Footer container detail:", detail)

  return (
    <footer className="uk-section uk-section-secondary uk-padding-remove-bottom">
      <div className="uk-container">
        <div className="uk-grid uk-grid-large" uk-grid="true">
          <div className="uk-width-1-2@m">
            <h2>{detail.title}</h2>
            <p>
              {detail.description}
              {/* <a
                className="adu-footer-link"
                href="mailto:info@adudev.co.uk"
                target="_blank"
                rel="noreferrer"
                alt=""
              >
                {detail.link}
              </a> */}
            </p>
            <SocialLinks />
          </div>

          <FooterLinks />
        </div>

        <div className="uk-flex uk-flex-between uk-flex-wrap">
          <div className="uk-margin-top uk-margin-bottom">{detail.rights}</div>

          <div className="uk-margin-top uk-margin-bottom">
            <a
              className="adu-footer-link"
              href="https://gatsby-portfolio-adu-dev.netlify.app/"
              target="_blank"
              rel="noreferrer"
            >
              Designed and built by AduDev
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
