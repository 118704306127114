import React from "react"
import { Link } from "gatsby"

const HomeLink = () => {
  return (
    <>
      <li>
        <Link
          to="/"
          uk-tooltip="title: Home; pos: bottom-center"
          uk-icon="icon: home"
        ></Link>
      </li>
    </>
  )
}

export default HomeLink
