import React from "react"
import { FaShopify, FaGlobe, FaStarHalfAlt } from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaShopify className="service-icon" />,
    title: "Shopify",
    text: `Source content from shopify to your frontend. Compile and build faster ecommerce online business using shopify as your backend payment system.`,
    slug: "/services/partner",
  },
  {
    id: 2,
    icon: <FaShopify className="service-icon" />,
    title: "Strapi",
    text: `Deploy and host on Content Delivery Networks, delivering content to visitors faster than traditional servers.`,
    slug: "/services/partner/",
  },
  {
    id: 3,
    icon: <FaStarHalfAlt className="service-icon" />,
    title: "SkyPencil",
    text: `Logo Design, Frontend web application themes design, marketing and social media branding. Start from pencil to digital on-line profiles`,
    slug: "/services/partner",
  },
  {
    id: 4,
    icon: <FaGlobe className="service-icon" />,
    title: "Wild Apricot",
    text: `Membership web applications with wild apricot cms, including events, subscription services, blogs, and a lot more`,
    slug: "/services/partner",
  },
]

export default data
