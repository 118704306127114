import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SwitcherDetails = ({ visionDetails, missionDetails }) => {
  //console.log("Switcher Details - visionDetails:", visionDetails)
  //console.log("Switcher Details - missionDetails:", missionDetails)
  return (
    <>
      {visionDetails ? (
        <>
          <div>
            {visionDetails.image && (
              <GatsbyImage
                to={visionDetails.slug}
                image={getImage(
                  visionDetails.image.localFile.childImageSharp.gatsbyImageData
                )}
                alt={visionDetails.title}
                title={visionDetails.title}
              />
            )}
          </div>
          <div uk-scrollspy-class="uk-animation-slide-right-medium">
            <h6 className="uk-text-primary">{visionDetails.adudev}</h6>
            <h2 className="uk-margin-small-top">{visionDetails.title}</h2>
            <p>{visionDetails.content}</p>
            <div className="uk-grid uk-child-width-1-2@s" uk-grid="true">
              {visionDetails.startUp.map((item, index) => {
                return (
                  <div key={index}>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <Link
                      to={item.slug}
                      className="uk-button uk-button-default uk-margin-small-top uk-dark"
                      uk-icon="arrow-right"
                    >
                      LEARN MORE
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {missionDetails.image && (
              <GatsbyImage
                to={missionDetails.slug}
                image={getImage(
                  missionDetails.image.localFile.childImageSharp.gatsbyImageData
                )}
                alt={missionDetails.title}
                title={missionDetails.title}
              />
            )}
          </div>
          <div uk-scrollspy-class="uk-animation-slide-right-medium">
            <h6 className="uk-text-primary">{missionDetails.adudev}</h6>
            <h2 className="uk-margin-small-top">{missionDetails.title}</h2>
            <p>{missionDetails.content}</p>
            <div className="uk-grid uk-child-width-1-2@s" uk-grid="true">
              {missionDetails.partners.map((item, index) => {
                return (
                  <div key={index}>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                    <Link
                      to={item.slug}
                      className="uk-button uk-button-default uk-margin-small-top uk-dark"
                      uk-icon="arrow-right"
                    >
                      LEARN MORE
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SwitcherDetails
