import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Markdown from "react-markdown"

const NewsItem = article => {
  return (
    <div>
      <header>
        <h2 className="uk-margin-remove-adjacent uk-text-bold uk-margin-small-bottom">
          <Link to={article.slug} className="uk-link-reset ">
            {article.title}
          </Link>
        </h2>
        <p className="uk-article-meta">
          Written on{" "}
          <span className="uk-text-small uk-text-muted">
            <Moment format="MMM Do YYYY">{article.date}</Moment>
          </span>
          . Posted in <a href="/">{article.category.name}</a> |{" "}
          <span uk-icon="icon: future" /> Takes 7 min reading.
        </p>
      </header>
      <figure className="">
        {article.image && (
          <GatsbyImage
            image={getImage(
              article.image.localFile.childImageSharp.gatsbyImageData
            )}
            aspectratio={21 / 9}
            alt="text"
            title={article.title}
            style={{
              width: "100%",
              //backgroundPosition: "center, center",
              //backgroundRepeat: "noRepeat",
              //backgroundSize: "cover",
              //position: "relative",
            }}
          />
        )}
        <figcaption className="uk-text-center uk-text-muted uk-margin-small-top">
          {article.caption}
        </figcaption>
      </figure>

      <Markdown children={article.longDescription} />

      <div className="uk-flex">
        <div>
          <Link
            to={article.link}
            className="uk-button uk-button-default uk-button-small uk-animation-fade uk-margin-top"
          >
            READ MORE
          </Link>
        </div>
      </div>

      <hr />
    </div>
  )
}

export default NewsItem
