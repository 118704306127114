import React from "react"
import { MdSupportAgent } from "react-icons/md"
import {
  FaUsersCog,
  FaServicestack,
  FaCode,
  FaHandsHelping,
  FaCogs,
} from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaUsersCog className="service-icon" />,
    title: "Agile Methodology",
    text: `App development is time-consuming, but our team of developers speed up this process adopting agile development methodologies.`,
    slug: "/services/fullStack/",
  },
  {
    id: 2,
    icon: <FaServicestack className="service-icon" />,
    title: "Quality of Service",
    text: `building partnerships with our clients, we are truly committed to delivering the highest-quality, result and user experience.`,
    slug: "/services/fullStack",
  },
  {
    id: 3,
    icon: <FaCode className="service-icon" />,
    title: "Project Delivery",
    text: `App development can be a long process. With over 30 years of experience, we ensure timely delivery to turn vision into bisinesses.`,
    slug: "/services/fullStack",
  },
  {
    id: 4,
    icon: <FaHandsHelping className="service-icon" />,
    title: "Client Satisfaction",
    text: `Having delivered successful projects to our partners and client gives us the kown how to cater to our client requirements.`,
    slug: "/services/fullStack/",
  },
  {
    id: 5,
    icon: <FaCogs className="service-icon" />,
    title: "Customized Solutions",
    text: `Our development teams offer custom web and mobile app solutions. We dedicate our efforts to transforming and breathing life into your app vision.`,
    slug: "/services/fullStack",
  },
  {
    id: 6,
    icon: <MdSupportAgent className="service-icon" />,
    title: "Continued Support",
    text: `Collaboration after project delivered is key. We provide continuing support, maintenance and administration as needs arise building partnerships.`,
    slug: "/services/fullStack",
  },
]

export default data
