const data = [
  {
    id: 1,
    title: "Vision",
    slug: "/",
  },
  {
    id: 2,
    title: "Mission",
    slug: "/",
  },
  {
    id: 3,
    title: "Values",
    slug: "/",
  },
]

export default data
