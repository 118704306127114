import React from "react"
import { FeaturedBlog } from "../../components"

const FeaturedBlogs = ({ blogs }) => {
  //console.log("FeaturedBlogs component - blogs:", blogs)
  return (
    <div className="uk-container uk-margin-medium-top">
      <h3 className="uk-heading-line uk-text-bold">
        <span>Featured Blogs</span>
      </h3>
      <div uk-slider="velocity: 5">
        <div className="uk-position-relative">
          <div className="uk-slider-container">
            <ul className="uk-slider-items uk-child-width-1-2@m uk-grid uk-grid-medium news-slide">
              {blogs.map((blog, index) => {
                return <FeaturedBlog key={blog.id} index={index} {...blog} />
              })}
            </ul>
          </div>
          <div className="uk-hidden@l uk-dark">
            <a
              className="uk-position-center-left uk-position-small"
              href="/"
              aria-label="previous"
              uk-slidenav-previous="true"
              uk-slider-item="previous"
            >
              {null}
            </a>
            <a
              className="uk-position-center-right uk-position-small"
              href="/"
              aria-label="next"
              uk-slidenav-next="true"
              uk-slider-item="next"
            >
              {null}
            </a>
          </div>
          <div className="uk-visible@l">
            <a
              className="uk-position-center-left-out uk-position-small"
              href="/"
              aria-label="previous"
              uk-slidenav-previous="true"
              uk-slider-item="previous"
            >
              {null}
            </a>
            <a
              className="uk-position-center-right-out uk-position-small"
              href="/"
              aria-label="next"
              uk-slidenav-next="true"
              uk-slider-item="next"
            >
              {null}
            </a>
          </div>
        </div>
        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin">
          <li></li>
        </ul>
      </div>
    </div>
  )
}

export default FeaturedBlogs
