import React from "react"

const Archive = () => {
  return (
    <>
      <h4 className="uk-heading-line uk-text-bold">
        <span>Archive</span>
      </h4>
      <ul className="uk-list">
        <li>
          <a href="/">March</a>
        </li>
        <li>
          <a href="/">February</a>
        </li>
        <li>
          <a href="/">January</a>
        </li>
        <li>
          <a href="/">
            December <small>(2021)</small>
          </a>
        </li>
        <li>
          <a href="/">
            November <small>(2021)</small>
          </a>
        </li>
        <li>
          <a href="/">
            October <small>(2021)</small>
          </a>
        </li>
        <li>
          <a href="/">
            September <small>(2021)</small>
          </a>
        </li>
        <li>
          <a href="/">
            August <small>(2021)</small>
          </a>
        </li>
      </ul>
    </>
  )
}

export default Archive
