import React from "react"
import { TitleV2 } from "../../components"
import Mobile from "../../constants/mobileServices"

const MobileServices = ({ image, text, title }) => {
  //console.log("MobileServices, Mobile - data:", Mobile)
  //console.log("MobileServices - image:", image)

  return (
    <div className="uk-container uk-margin-small-top">
      <div className="uk-margin-remove-bottom">
        <TitleV2 title={title} styleClass="underline" />
        <div className="uk-text-center">{text}</div>
      </div>

      <div className="uk-section design-card uk-padding-remove-top uk-padding-remove-bottom uk-margin-medium-top">
        <div>
          <div
            className="uk-child-width-1-3@m uk-grid-match uk-text-center"
            uk-grid="true"
          >
            {Mobile.map(service => {
              const { id, icon, title, text } = service

              return (
                <div key={id} className=" design-card uk-text-center">
                  <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline">
                    <span
                      className="uk-text-large"
                      style={{ color: "#ff7f00" }}
                    >
                      {icon}
                    </span>

                    <h3 className="uk-card-title uk-margin-remove-top">
                      {title}
                    </h3>
                    <div className="underline" />
                    <p>{text}</p>
                  </div>
                </div>
              )
            })}
          </div>
          {/* <div className="uk-text-center uk-text-muted uk-section uk-section-small uk-margin-remove-top">
            Here at AduDev we use Gatsby JS V4 to disrupt the whole online
            industry, by setting new standard for site performance and user
            experience. Gatsby will scale large sites with tens of thousands of
            pages, separate concerns, and deliver a top-notch developer
            experience.
            <br />
            <a
              href="#featured"
              title="Featured Mobile Generator"
              uk-icon="arrow-down"
              uk-scroll="duration: 400"
              className="uk-button uk-button-secondary uk-margin-top"
            >
              Featured Mobile Generator
            </a>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default MobileServices
