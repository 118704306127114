import React from "react"
//import { Link } from "gatsby"
import { TitleV2 } from "../../components"
import FullStack from "../../constants/fullStack"

const FullStackServices = ({ image, text, title }) => {
  //console.log("FullStackServices, FullStack - data:", FullStack)
  //console.log("FullStackServices - image:", image)

  return (
    <div className="uk-container uk-margin-small-top">
      <div>
        <TitleV2 title={title} styleClass="underline" />
      </div>

      <div>{text}</div>

      <div className="uk-section design-card uk-padding-remove-top uk-padding-remove-bottom uk-margin-medium-top">
        <div>
          <div
            className="uk-child-width-1-3@m uk-grid-match uk-text-center"
            uk-grid="true"
          >
            {FullStack.map(service => {
              const { id, icon, title, text } = service

              return (
                <div key={id} className=" design-card uk-text-center">
                  <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline">
                    <span
                      className="uk-text-large"
                      style={{ color: "#ff7f00" }}
                    >
                      {icon}
                    </span>

                    <h3 className="uk-card-title uk-margin-remove-top">
                      {title}
                    </h3>
                    <div className="underline" />
                    <p>{text}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="uk-text-center uk-text-muted uk-section uk-section-small uk-margin-remove-top">
            See below as why you as a business would consider full-stack
            development services that help you to relise you dreams and vision
            <br />
            <a
              href="#fullstack"
              title="Full Stack Development services"
              uk-icon="arrow-down"
              uk-scroll="duration: 400"
              className="uk-button uk-button-secondary uk-margin-top"
            >
              Why Full-Stack Development
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullStackServices
