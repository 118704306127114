import React from "react"
//import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import Moment from "react-moment"
import Markdown from "react-markdown"

const ProjectsCard = ({
  category,
  categoryText,
  description,
  headerTitle,
  image,
  sharedStack,
  time,
  title,
  link,
  github,
  switcher,
  switcherId,
}) => {
  //console.log("Project Component - project:", project)
  return (
    <li>
      <div className="design-card">
        {/* <!-- card --> */}
        <div className="uk-card uk-card-small uk-card-default">
          <div className="uk-card-header">
            <div className="uk-grid uk-grid-small uk-text-small" uk-grid="true">
              <div className="uk-width-expand">
                <span className="cat-txt">{headerTitle}</span>
              </div>
              <div className="uk-width-auto uk-text-right uk-text-muted">
                <span uk-icon="icon: clock; ratio: 1"></span> {time}
              </div>
            </div>
          </div>
          <div className="uk-card-media">
            <div className="uk-inline-clip uk-transition-toggle" tabIndex="-1">
              <div className="uk-width-auto">
                {image && (
                  <GatsbyImage
                    alt={headerTitle}
                    image={getImage(
                      image.localFile.childImageSharp.gatsbyImageData
                    )}
                  />
                )}
              </div>
              <div className="uk-transition-slide-bottom uk-position-bottom uk-overlay uk-overlay-primary">
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="preview-button"
                  alt="preview-button"
                  text="preview-button"
                  className="uk-button uk-button-default uk-button-small uk-margin uk-margin-medium-top"
                >
                  Preview
                </a>
                <div />
                <span uk-icon="icon: heart; ratio: 1"></span> 225{" "}
                <span uk-icon="icon: comment; ratio: 1"></span>35
              </div>
            </div>
          </div>

          <div className="uk-card-body">
            <h6 className="uk-margin-small-bottom uk-margin-remove-adjacent uk-text-bold">
              {title}
            </h6>
            <p className="uk-text-small uk-text-muted">{description}</p>

            <div>
              <div>
                <ul
                  className="uk-subnav uk-subnav-pill uk-align-left"
                  uk-switcher={switcher}
                >
                  <li className="uk-margin-small-bottom">
                    <a href="/">Category</a>
                  </li>
                  <li className="uk-margin-small-bottom">
                    <a href="/">Stack</a>
                  </li>
                  <li>
                    <a href="/">tags</a>
                  </li>
                </ul>
              </div>

              <div>
                <ul className={switcherId}>
                  <li>
                    <h5>{category}</h5>
                    <div className="uk-text-small uk-text-muted uk-padding-remove-top">
                      <Markdown children={categoryText} />
                    </div>
                  </li>
                  <li>
                    {/* <h5>Stack used for build</h5> */}
                    <div className="project-stack">
                      {sharedStack.map(item => {
                        return <span key={item.id}>{item.title}</span>
                      })}
                    </div>
                  </li>
                  <li>
                    <h5>Associated Tags</h5>
                    <div className="project-stack">
                      <span>Case studies</span>
                      <br />
                      <span>Blog</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* <div uk-grid="true">
            <div className="uk-width-auto@m">
              <ul className="uk-tab-left" uk-tab={tab}>
                <li>
                  <a href="#">Active</a>
                </li>
                <li>
                  <a href="#">Item</a>
                </li>
                <li>
                  <a href="#">Item</a>
                </li>
              </ul>
            </div>
            <div className="uk-width-expand@m">
              <ul id={tabId} className="uk-switcher">
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </li>
                <li>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </li>
                <li>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur, sed do eiusmod.
                </li>
              </ul>
            </div>
          </div> */}

          <div className="uk-card-footer">
            <div
              className="uk-grid uk-grid-small uk-grid-divider uk-flex uk-flex-middle"
              uk-grid="true"
            >
              <div className="uk-width-expand uk-text-small">Herman Adu</div>
              <div className="uk-width-auto uk-text-right">
                <div className="uk-flex uk-dark">
                  <a
                    uk-tooltip="title: Twitter; pos: bottom-center"
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="twitter"
                    alt="twitter"
                    text="twitter"
                    className="uk-icon-button uk-margin-small-right"
                    uk-icon="icon: twitter; ratio: 1"
                  >
                    {null}
                  </a>
                  <a
                    uk-tooltip="title: Linkedin; pos: bottom-center"
                    href="https://www.linkedin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="linkedin"
                    alt="linkedin"
                    text="linkedin"
                    className="uk-icon-button uk-margin-small-right"
                    uk-icon="icon: linkedin; ratio: 1"
                  >
                    {null}
                  </a>
                  <a
                    uk-tooltip="title: Facebook; pos: bottom-center"
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="facebook"
                    alt="facebook"
                    text="facebook"
                    className="uk-icon-button uk-margin-small-right"
                    uk-icon="icon: facebook; ratio: 1"
                  >
                    {null}
                  </a>
                </div>
              </div>
              <div className="uk-width-auto uk-text-right">
                {github !== "Private Repository" ? (
                  <a
                    uk-tooltip="title: GitHub; pos: bottom-center"
                    href={github}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="github"
                    alt="github"
                    text="github"
                    className="uk-icon-button uk-margin-small-right"
                    uk-icon="icon: github; ratio: 1"
                  >
                    {null}
                  </a>
                ) : (
                  <span
                    className="uk-dark"
                    uk-tooltip="title: Private Repository; pos: bottom-center"
                    uk-icon="icon: github; ratio: 1.2"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- /card --> */}
      </div>
    </li>
  )
}

export default ProjectsCard
