import React from "react"
import { TitleV2 } from "../../../components"

const Purpose = () => {
  return (
    <div className="uk-section uk-section-muted">
      <div className="uk-container">
        {/* <h2>Our Purpose</h2> */}
        <TitleV2 title="Our Purpose" />
        <div>
          <p>
            AduDev Development and Consulting Services, (AduDev), has a vision
            to lead and support the creation and sustainable development of IT
            and on-line services for Startups and small to medium business
            everywhere. Led by professional with over thirty years of experience
            in our sectors we wish to enable our clients with the best solutions
            that best meet their needs, putting an understanding on how and why
            you system is built in a certian way. We want our Clients to have a
            solution in place thats fit for purpose rather than pluging their
            business into a theme that they feel they can work with.
          </p>
        </div>

        <div>
          <h4>We aim to:</h4>
          <ul className="uk-list uk-list-decimal">
            <li>
              Take an in-depth look at our clients business from start to finish
            </li>
            <li>
              Translate that research into IT Core Services needed for your
              business
            </li>
            <li>
              Take a look at your goals and ambitions, the longevity of the
              business and your plans
            </li>
            <li>
              Use the Core services to best suggest an architecture for our
              clients services
            </li>
            <li>
              Bring this thinking into the planing process as early as possible
            </li>
            <li>
              Help our clients to understand Build vs Buy with regards to their
              budget and services
            </li>
            <li>
              How the systems we build can offer realtime support for our
              clients services or products.
            </li>
            <li>
              Accept fit for purpose, never settle for just a theme build.
            </li>
          </ul>
        </div>
        <div>
          <h4>Our Approach:</h4>
          <p>
            Our Approach is to find the best approach that best suits our
            clients needs:
          </p>
          <ul className="uk-list uk-list-decimal">
            <li>
              Develop beautiful relationships with out clients enabling trust
              and Transparency
            </li>
            <li>
              To collaborate honestly with our clients helping them meet their
              needs
            </li>
            <li>
              To always beaware of our client budget structure and consult with
              integrity
            </li>
            <li>
              To provide the best fit solutions for our clients and their
              business, not just bells and whistles
            </li>
            <li>
              It's not just about the offering of bells and whistles for client
              sites
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Purpose
