import React from "react"
import { Link } from "gatsby"
import { FeaturedArticle, TitleV2 } from "../../../components"

const FeaturedArticles = ({ articles, title, styleClass }) => {
  //console.log("Featured articles container - articles:", articles)
  return (
    <section className="uk-section uk-section-small  uk-padding-remove-bottom uk-padding-remove-top uk-margin-medium-bottom">
      <div className="uk-container uk-padding-remove-bottom">
        <TitleV2 title={title} styleClass={styleClass} />
        <br />
        <div uk-slider="velocity: 5">
          <div className="uk-position-relative">
            <div className="uk-slider-container">
              <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-medium">
                {articles.map((article, index) => {
                  return (
                    <FeaturedArticle
                      key={article.strapiId}
                      index={index}
                      {...article}
                    />
                  )
                })}
              </ul>
            </div>

            <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

            <div className="uk-hidden@m uk-light">
              <Link
                className="uk-position-center-left uk-position-small"
                to="/"
                uk-slidenav-previous="true"
                uk-slider-item="previous"
              >
                {null}
              </Link>
              <Link
                className="uk-position-center-right uk-position-small"
                to="/"
                uk-slidenav-next="true"
                uk-slider-item="next"
              >
                {null}
              </Link>
            </div>
            <div className="uk-visible@m">
              <Link
                className="uk-position-center-left-out uk-position-small"
                to="/"
                uk-slidenav-previous="true"
                uk-slider-item="previous"
              >
                {null}
              </Link>
              <Link
                className="uk-position-center-right-out uk-position-small"
                to="/"
                uk-slidenav-next="true"
                uk-slider-item="next"
              >
                {null}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedArticles
