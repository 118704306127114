import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Seo from "./seo"
//import Navbar from "./Navigation/navbar"

import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons"
import "../Styles/main.scss"
import "uikit/dist/css/uikit.min.css"
import { Footer, Navbar } from "../containers"

const Layout = ({ name, mode, children, seo }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      UIkit.use(Icons)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          strapiHomepage {
            seo {
              metaTitle
              metaDescription
              shareImage {
                localFile {
                  publicURL
                }
              }
            }
          }
          strapiFooter {
            title
            description
            rights
            built
            link
          }
        }
      `}
      render={data => {
        const detail = data.strapiFooter

        return (
          <div data-name={name} data-mode={mode}>
            <Seo seo={seo} />
            <Navbar />
            <main>{children}</main>
            <Footer detail={detail} />
          </div>
        )
      }}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
