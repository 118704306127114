import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeaturedBlog = ({
  image,
  label,
  title,
  link,
  date,
  shortDescription,
}) => {
  //const imgPath = image.childImageSharp.gatsbyImageData
  //const imgPath = image.localFile.childImageSharp.url
  //console.log("Url", image)
  return (
    <>
      <li>
        <div
          style={{ borderTop: "none" }}
          className="blog-card uk-card uk-card-default uk-card-body uk-card-small uk-flex uk-flex-middle uk-card-default uk-border-rounded"
        >
          <div
            className="uk-grid uk-grid-medium uk-flex uk-flex-middle"
            uk-grid="true"
          >
            <div className="uk-width-1-3@s uk-width-2-5@m">
              <div className=" uk-cover-container">
                {image && (
                  <GatsbyImage
                    image={getImage(
                      image.localFile.childImageSharp.gatsbyImageData
                    )}
                    alt={title}
                    uk-cover="true"
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    layout="constrained"
                    style={{ aspectRatio: "3/2", width: "100%" }}
                    //style={{ aspectRatio: "3/2", width: "250px" }}
                    //style={{ aspectRatio: "2/1", height: "150px" }}
                  />
                )}
              </div>
            </div>
            <div className="uk-width-2-3@s uk-width-3-5@m">
              <span
                className="uk-label uk-label-warning"
                style={{ fontSize: "0.75rem" }}
              >
                {label}
              </span>
              <h3 className="uk-card-title uk-margin-small-top uk-margin-remove-bottom">
                <a className="uk-link-reset" href={link}>
                  {title}
                </a>
              </h3>
              <span className="uk-article-meta">{date}</span>
              <p className="uk-margin-small">{shortDescription}</p>
            </div>
          </div>
        </div>
      </li>
    </>
  )
}

export default FeaturedBlog
