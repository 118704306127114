import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Advert, Archive, NewsItem } from "../../components"
import Adverts from "../../constants/Adverts/newsAdverts"

const LatestNews = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiArticle(
            filter: {
              featured: { eq: true }
              category: { name: { eq: "news" } }
            }
          ) {
            nodes {
              id
              slug
              title
              link
              description
              date
              featured
              category {
                name
              }
              caption
              longDescription
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                  }
                }
              }
              author {
                name
                picture {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 30
                        height: 30
                        placeholder: BLURRED
                        formats: [AUTO]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const articles = data.allStrapiArticle.nodes

        return (
          <div className="uk-section uk-section-default uk-padding-remove-bottom">
            <div className="uk-container">
              <div className="uk-grid" uk-grid="true">
                <div className="uk-width-2-3@m">
                  <h3 className="uk-heading-line uk-text-bold">
                    <span>Latest News</span>
                  </h3>
                  <article className="uk-section uk-section-small uk-padding-remove-top">
                    {articles.map((article, index) => {
                      return (
                        <NewsItem key={article.id} index={index} {...article} />
                      )
                    })}
                  </article>
                </div>

                <div className="uk-width-1-3@m">
                  <Archive />
                  {Adverts.map((advert, index) => {
                    return <Advert key={advert.id} index={index} {...advert} />
                  })}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default LatestNews
