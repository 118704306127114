import React from "react"
import { AdminLinks, Logo, SocialLinks } from "../../../components"

const Mobile = () => {
  return (
    <nav
      className="uk-navbar-container uk-navbar-transparent uk-hidden@m"
      uk-navbar="true"
    >
      {/* left section for mobile nav view */}
      <div className="uk-navbar-left ">
        <Logo />
      </div>

      {/* right section for mobile nav view toggle button for the off canvas section */}
      <div className="uk-navbar-right uk-dark">
        <button
          className="uk-navbar-toggle uk-button uk-disabled"
          uk-navbar-toggle-icon="true"
          uk-toggle="target: #mobile-menu"
          aria-label="Menu"
        >
          &nbsp;&nbsp;
        </button>
      </div>

      {/* center nav section for mobile nav view  */}
      <div id="mobile-menu" className="uk-navbar-center ">
        <SocialLinks />
        <AdminLinks />
      </div>
    </nav>
  )
}

export default Mobile
