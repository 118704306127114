import React from "react"
import { Link } from "gatsby"

const FooterLinks = () => {
  return (
    <>
      <div className="uk-width-1-6@m">
        <h5>ABOUT</h5>
        <ul className="uk-list">
          <li>
            <Link className="adu-footer-link uk-disabled" to="/about">
              Team
            </Link>
          </li>
          <li>
            <Link className="uk-disabled adu-footer-link" to="/about">
              Our Values
            </Link>
          </li>
          <li>
            <Link className="adu-footer-link uk-disabled" to="/contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div className="uk-width-1-6@m">
        <h5>SERVICES</h5>
        <ul className="uk-list">
          <li>
            <Link className="adu-footer-link uk-disabled" to="/services">
              consultation
            </Link>
          </li>
          <li>
            <Link className="adu-footer-link uk-disabled" to="/services">
              prices
            </Link>
          </li>
          <li>
            <Link className="adu-footer-link uk-disabled" to="/services">
              plans
            </Link>
          </li>
        </ul>
      </div>

      <div className="uk-width-1-6@m">
        <h5>BUSINESS</h5>
        <ul className="uk-list">
          <li>
            <Link className="adu-footer-link uk-disabled" to="/services">
              Terms
            </Link>
          </li>
          <li>
            <Link className="adu-footer-link uk-disabled" to="/services">
              Privacy
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default FooterLinks
