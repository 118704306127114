import React from "react"
import { Link } from "gatsby"
//import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeaturedArticle = ({
  image,
  author,
  date,
  title,
  description,
  slug,
  link,
}) => {
  return (
    <>
      <li>
        <div>
          <div
            className="uk-card uk-card-default uk-card-small"
            style={{ borderTop: "none" }}
          >
            <div className="uk-card-media-top">
              <Link to={link} alt="alt text" title={title}>
                {image && (
                  <GatsbyImage
                    image={getImage(
                      image.localFile.childImageSharp.gatsbyImageData
                    )}
                    alt={title}
                    title={title}
                    style={{
                      backgroundPosition: "center, center",
                      backgroundRepeat: "noRepeat",
                      backgroundSize: "cover",
                      position: "relative",
                    }}
                  />
                )}
                {null}
              </Link>
            </div>
            <div className="uk-card-header">
              <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                <div className="uk-width-auto">
                  {author && (
                    <GatsbyImage
                      image={getImage(
                        author.picture.localFile.childImageSharp.gatsbyImageData
                      )}
                      alt={title}
                      title={title}
                      className="uk-inline-clip uk-border-circle"
                    />
                  )}
                </div>
                <div className="uk-width-expand">
                  <h6 className="uk-margin-remove-bottom uk-text-bold">
                    {author.name}
                  </h6>
                  <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                    <time dateTime="2016-04-01T19:00">{date}</time>
                  </p>
                </div>
              </div>
            </div>
            <div className="uk-card-body">
              <h4 className="uk-margin-small-bottom uk-text-bold">{title}</h4>
              <span className="uk-text-small">{description}</span>
              <br />
              <Link
                to={link}
                alt="alt text"
                title="Title text"
                className="uk-button uk-button-default uk-button-small uk-animation-fade uk-margin-top"
              >
                READ MORE
              </Link>
            </div>
          </div>
        </div>
      </li>
    </>
  )
}

export default FeaturedArticle
