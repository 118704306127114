import React from "react"
//import { StaticImage } from "gatsby-plugin-image"
//import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import {
  Logo,
  HomeLink,
  //AboutLink,
  //ServiceLinks,
  //ProjectLink,
  //NewsLinks,
  //ContactLink,
  //SocialLinks,
  //AdminLinks,
} from "../../../components"

const Desktop = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiNavbarCard {
            nodes {
              department
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      placeholder: BLURRED
                      width: 480
                      height: 180
                    )
                  }
                }
              }
              title
              description
              likes
              comments
              author {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 50
                      height: 50
                      placeholder: BLURRED
                      formats: [AUTO]
                    )
                  }
                }
              }
              name
              slug
            }
          }
        }
      `}
      render={data => {
        /* const {
          allStrapiNavbarCard: { nodes: navbarCard },
        } = data */
        //const serviceCard = navbarCard[0]
        //const projectCard = navbarCard[1]
        //const newsCard = navbarCard[2]

        return (
          <nav
            className="uk-navbar-container uk-navbar-transparent uk-visible@s"
            uk-navbar="true"
          >
            {/* left section for desktop nav view  */}
            <div className="uk-navbar-left uk-margin-small-left uk-visible@m">
              <Logo />
            </div>
            {/* <div className="uk-navbar-left uk-margin-small-left uk-margin-small-top  ">
              <Link to="/" className="uk-logo">
                <StaticImage
                  src="../../../images/adudev-Logo.png"
                  placeholder="blurred"
                  alt="Adudev logo"
                />
              </Link>
            </div> */}

            {/* center section for desktop nav view  */}
            <div className="uk-navbar-center uk-visible@m">
              <ul className="uk-navbar-nav uk-nav-primary">
                <HomeLink />
                {/* <AboutLink /> */}
                {/* <ServiceLinks serviceCard={serviceCard} /> */}
                {/* <ProjectLink /> */}
                {/* <ProjectLinks projectCard={projectCard} /> */}
                {/*  <NewsLinks newsCard={newsCard} /> */}
                {/* <ContactLink /> */}
              </ul>
            </div>

            {/* right section for desktop nav view  */}
            <div className="uk-navbar-right">
              {/* <!-- hide social icons from tablet and mobile --> */}
              <div className="uk-navbar-item  uk-visible@m">
                {/* <SocialLinks /> */}
                {/* <AdminLinks /> */}
              </div>
            </div>
          </nav>
        )
      }}
    />
  )
}

export default Desktop
