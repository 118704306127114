import React from "react"
import { FaCode, FaSketch, FaAndroid } from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Web Development",
    text: `Click here to view all AduDev web development services and how we can help you grow and acheive your goals`,
    slug: "/services/development",
  },
  {
    id: 2,
    icon: <FaAndroid className="service-icon" />,
    title: "Partner Development",
    text: `Click here, see our partners development scheme check out the different development services on offer`,
    slug: "/services/partner",
  },
  {
    id: 3,
    icon: <FaSketch className="service-icon" />,
    title: "Buisness Operations",
    text: `Click here, for our business models, business operations and consulting services for our clients and projects`,
    slug: "/services/operations",
  },
]

export default data
