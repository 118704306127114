import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"

const Competitive = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiCompetitive {
            competitiveDetails {
              id
              reason
              title
              description
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      placeholder: BLURRED
                      width: 1000
                      height: 650
                    )
                  }
                }
              }
            }
            competitiveColumn {
              id
              title
              description
              slug
            }
          }
        }
      `}
      render={data => {
        const strapiCompetitive = data.strapiCompetitive

        const { reason, title, description, image } =
          strapiCompetitive.competitiveDetails

        const valueColumns = strapiCompetitive.competitiveColumn

        return (
          <div
            className="uk-grid uk-child-width-1-2@l uk-flex-middle"
            data-uk-grid
            data-uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium"
          >
            <div>
              {image && (
                <GatsbyImage
                  to="/"
                  image={getImage(
                    image.localFile.childImageSharp.gatsbyImageData
                  )}
                  alt={title}
                  title={title}
                />
              )}
            </div>
            <div data-uk-scrollspy-classname="uk-animation-slide-right-medium">
              <h6 className="uk-text-primary">{reason}</h6>
              <h2 className="uk-margin-small-top">{title}</h2>
              <p className="subtitle-text">{description}</p>
              <div className="uk-grid uk-child-width-1-2@s" data-uk-grid>
                {valueColumns.map((item, index) => {
                  const { id, title, description, slug } = item
                  //console.log("item:", id, title, description, slug)

                  return (
                    <div key={id}>
                      <h4>{title}</h4>
                      <p>{description}</p>
                      <Link to={slug} alt="">
                        Learn more.
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default Competitive
