const React = require("react")
const Layout = require("./src/components/layout").default

exports.wrapPageElement = ({ element, ...restProps }, ...args) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <Layout name="wrapPageElement" props={restProps} args={args} mode="browser">
      {element}
    </Layout>
  )
}
