import React from "react"
import { FaCode, FaGlobe, FaServer } from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Performance",
    text: `Source content faster. Compile and build faster. Build the fastest websites utilising the latest progressive technologies.`,
    slug: "/services/static",
  },
  {
    id: 2,
    icon: <FaGlobe className="service-icon" />,
    title: "Scalability",
    text: `Deploy to a Content Delivery Network, delivering your site to visitors faster than traditional servers with fewer resourses.`,
    slug: "/services/fullStack/",
  },
  {
    id: 3,
    icon: <FaServer className="service-icon" />,
    title: "Security",
    text: `Statically generated sites have less vulnerabilities than traditional websites and monolithic platforms.`,
    slug: "/services/mobile",
  },
]

export default data
