import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Logo = () => {
  return (
    <>
      <Link to="/" className="uk-logo">
        <StaticImage
          src="../../images/adudev-Logo.png"
          placeholder="blurred"
          alt="Adudev logo"
          className="uk-logo"
          //style={{ height: 50, width: 50 }}
        />
      </Link>
    </>
  )
}

export default Logo
