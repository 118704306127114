import React from "react"
import { Link } from "gatsby"
import { AdminLinks, SocialLinks } from "../../../components"

const OffCanvas = () => {
  return (
    <div id="mobile-menu" uk-offcanvas="overlay: true">
      <div className="uk-offcanvas-bar">
        <button
          className="uk-offcanvas-close "
          type="button"
          uk-close="true"
          aria-label="button"
        />
        <div className="bar-content uk-position-relative">
          <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
            <li className="uk-nav-header">AduDev Logo</li>
            <li className="uk-active">
              <Link
                className="uk-nav-header"
                to="/"
                uk-icon="icon: home; ratio: 1.2"
              ></Link>
            </li>
            <li>
              <Link className="uk-nav-header" to="/about">
                About us
              </Link>
            </li>

            <li className="uk-parent">
              <Link to="/" className="uk-nav-header">
                Services
              </Link>
              <ul className="uk-nav-sub">
                <li className="uk-margin-small-bottom">
                  <Link to="/services">
                    <span
                      className="uk-margin-small-right"
                      data-uk-icon="icon: star; ratio: 1.2"
                    />
                    Our Services
                  </Link>
                </li>
                <li className="uk-active">
                  <Link className="uk-nav-header" to="/services/development">
                    Web Development
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link to="/services/static">Static Site Generation</Link>
                    </li>
                    <li>
                      <Link to="/services/fullStack">
                        Full-Stack Applications
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/mobile">Mobile App Development</Link>
                    </li>
                    <li>
                      <Link to="/services/clientServices">
                        Managing our Clients Services
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="uk-active">
                  <Link
                    className="uk-active  uk-nav-header"
                    to="/services/operations"
                  >
                    Business Operations
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link to="/services/operations">Build vs Buy</Link>
                    </li>
                    <li>
                      <Link to="/services/operations">Respect your budget</Link>
                    </li>
                    <li>
                      <Link to="/services/operations">Vision & Stragegy</Link>
                    </li>
                    <li>
                      <Link to="/services/operations">Tactics & Execution</Link>
                    </li>
                  </ul>
                </li>

                <li className="uk-active">
                  <Link className="uk-nav-header" to="/services/partner">
                    Partner Development
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link to="/services/partner">Shopify</Link>
                    </li>
                    <li>
                      <Link to="/services/partner">Strapi</Link>
                    </li>
                    <li>
                      <Link to="/services/partner">SkyPencil</Link>
                    </li>
                    <li>
                      <Link to="/services/partner">Wild Apericot</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link className="uk-nav-header" to="/projects">
                Projects
              </Link>
            </li>

            {/* <li className="uk-parent">
              <Link to="/" className="uk-nav-header">
                Projects
              </Link>
              <ul className="uk-nav-sub">
                <li className="uk-active">
                  <Link className="uk-nav-header" to="/projects/client">
                    Client projects
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link to="/projects/client">AduDev LTD</Link>
                    </li>
                    <li>
                      <Link to="/projects/client">
                        ION Institution of Neurodiversity
                      </Link>
                    </li>
                    <li>
                      <Link to="/projects/client">Helix Director services</Link>
                    </li>
                    <li>
                      <Link to="/projects/client">Item</Link>
                    </li>
                  </ul>
                </li>

                <li className="uk-active">
                  <Link className="uk-nav-header" to="/projects/partner">
                    Strategic Partners
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link to="/projects/partner">SkyPencil</Link>
                    </li>
                    <li>
                      <Link to="/projects/partner">Shopify</Link>
                    </li>
                    <li>
                      <Link to="/projects/partner">Strapi</Link>
                    </li>
                    <li>
                      <Link to="/projects/partner">Item</Link>
                    </li>
                  </ul>
                </li>

                <li className="uk-active">
                  <Link className="uk-nav-header" to="/projects/adudev">
                    AduDev projects
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link to="/projects/adudev">
                        Social Media Sharing Application
                      </Link>
                    </li>
                    <li>
                      <Link to="/projects/adudev">On-line Events System</Link>
                    </li>
                    <li>
                      <Link to="/projects/adudev">Realtime Support Chat</Link>
                    </li>
                    <li>
                      <Link to="/projects/adudev">Item</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}

            <li className="uk-parent">
              <Link to="/" className="uk-nav-header">
                News
              </Link>
              <ul className="uk-nav-sub">
                <li>
                  <Link className="uk-nav-header" to="/articles">
                    Latest Blogs
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 1
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 2
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 3
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 4
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link className="uk-nav-header" to="/articles">
                    Press Releases
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 1
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 2
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 3
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 4
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link className="uk-nav-header" to="/articles">
                    Media Coverage
                  </Link>
                  <ul className="uk-nav-sub">
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 1
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 2
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 3
                      </Link>
                    </li>
                    <li>
                      <Link className="uk-light" to="/articles">
                        item 4
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link className="uk-nav-header" to="/contact">
                Contact us
              </Link>
            </li>
            {/* Secondary nav not needed yet                
              <li className="uk-nav-header">SECONDARY</li>
              <li className="uk-parent">
                <a>Parent</a>
                <ul className="uk-nav-sub">
                  <li>
                    <a>Sub item</a>
                  </li>
                  <li>
                    <a>Sub item</a>
                  </li>
                </ul>
              </li>
              <li className="uk-parent">
                <a>Parent</a>
                <ul className="uk-nav-sub">
                  <li>
                    <a>Sub item</a>
                  </li>
                  <li>
                    <a>Sub item</a>
                  </li>
                </ul>
              </li> */}
            <li className="uk-nav-header">Featured Pages</li>
            <li className="uk-active">
              <Link to="/services">
                <span
                  className="uk-margin-small-right"
                  data-uk-icon="icon: star; ratio: 1.2"
                />
                Services
              </Link>
            </li>
            <li className="uk-active">
              <Link to="/projects">
                <span
                  className="uk-margin-small-right"
                  data-uk-icon="icon: image; ratio: 1.2"
                />
                Projects
              </Link>
            </li>
            <li className="uk-active">
              <Link to="/">
                <span
                  className="uk-margin-small-right"
                  data-uk-icon="icon: info; ratio: 1.2"
                />
                Price Plans
              </Link>
            </li>
            <li className="uk-nav-divider" />
            <li className="uk-active">
              <Link to="/">
                <span
                  className="uk-margin-small-right"
                  data-uk-icon="icon: sign-in; ratio: 1.2"
                />
                Login
              </Link>
            </li>
            <li className="uk-active">
              <Link to="/">
                <span
                  className="uk-margin-small-right"
                  data-uk-icon="icon: sign-in ; ratio: 1.2"
                />
                Login-Dark
              </Link>
            </li>
            <li className="uk-nav-header">Social Media</li>
            <div className="uk-navbar-item ">
              <SocialLinks />
              <AdminLinks />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default OffCanvas
