import React from "react"
import { Link } from "gatsby"
import { TitleV2 } from "../../components"
import ClientServices from "../../constants/clientServices"

const ClientServicesPage = ({ text, title }) => {
  //console.log("ClientServices:", ClientServices)
  return (
    <div className="uk-container uk-margin-small-top">
      <div className="uk-margin-remove-bottom">
        <TitleV2 title={title} styleClass="underline" />
      </div>

      <div className="uk-margin-remove-bottom">{text}</div>

      <div className="uk-section design-card">
        <div>
          <div
            className="uk-child-width-1-3@m uk-grid-match uk-text-center"
            uk-grid="true"
          >
            {ClientServices.map(service => {
              const { id, icon, title, text, slug } = service

              return (
                <Link key={id} to={slug} alt={title} title={title}>
                  <div className=" design-card uk-text-center uk-margin-bottom">
                    <div className="uk-card uk-card-default uk-box-shadow-medium uk-card-hover uk-card-body uk-inline">
                      <span
                        className="uk-text-large"
                        style={{ color: "#ff7f00" }}
                      >
                        {icon}
                      </span>

                      <h3 className="uk-card-title uk-margin-remove-top">
                        {title}
                      </h3>
                      <div className="underline" />
                      <p>{text}</p>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientServicesPage
