import React from "react"

const Advert = ({ title, content, slug }) => {
  return (
    <div className="uk-padding-remove">
      <h4 className="uk-heading-line uk-text-bold uk-margin uk-margin-medium-top uk-margin-small-bottom">
        <span>{title}</span>
      </h4>
      <div className="uk-tile uk-tile-small uk-tile-muted uk-border-rounded">
        {content}
        <br />
        <br />
        <a
          href={slug}
          title="Read More"
          className="uk-button uk-button-default uk-button-small uk-margin-small-right"
        >
          READ MORE
        </a>
      </div>
    </div>
  )
}

export default Advert
