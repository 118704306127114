import React from "react"

const Title = ({ title }) => {
  return (
    <div>
      <h2 className="uk-heading-line uk-text-center">
        <span>{title || "default title"}</span>
      </h2>
    </div>
  )
}

export default Title
