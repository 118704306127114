import React from "react"
import {
  SwitcherBtn,
  SwitcherDetails,
  SwitcherTiles,
} from "../../../components"

import switcherButtons from "../../../constants/visionSwitcher"

const Switcher = ({ visionDetails, missionDetails, switcherTiles }) => {
  return (
    <section
      id="content"
      className="uk-section-small uk-padding-remove-top uk-margin-small-top"
    >
      <div className="uk-container uk-container-large">
        <div className="uk-section uk-section-small uk-margin-small-top uk-padding-remove-top">
          <SwitcherBtn buttons={switcherButtons} />
        </div>

        <ul className="uk-switcher">
          <li>
            <div
              className="uk-grid uk-child-width-1-2@l uk-flex-middle"
              uk-grid="true"
              uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium"
            >
              <SwitcherDetails visionDetails={visionDetails} />
            </div>
          </li>
          <li>
            <div
              className="uk-grid uk-child-width-1-2@l uk-flex-middle"
              uk-grid="true"
              uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium"
            >
              <SwitcherDetails missionDetails={missionDetails} />
            </div>
          </li>
          <li>
            <div
              className="uk-grid uk-child-width-1-2@l uk-flex-middle"
              uk-grid="true"
              uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium"
            >
              <SwitcherTiles switcherTiles={switcherTiles} />
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Switcher
