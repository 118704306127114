import React from "react"
import { FaMobileAlt } from "react-icons/fa"
import { DiAndroid, DiApple } from "react-icons/di"
// DiAndroid
const data = [
  {
    id: 1,
    icon: <DiApple className="service-icon" />,
    title: "iOS  App Development",
    text: `Customised software apps running on the IOS platform. official catalog of the App Store digital store, where apps are subjected to security checks before being made available to users`,
    slug: "/services/development",
  },
  {
    id: 2,
    icon: <DiAndroid className="service-icon" />,
    title: "Android App Development",
    text: `Customised software apps running on the Android platform. Android platform is built for mobile devices, we provide services for smartphone or a tablet PC running on the Android OS`,
    slug: "/services/partner",
  },
  {
    id: 3,
    icon: <FaMobileAlt className="service-icon" />,
    title: "Progressive Web Apps",
    text: `PWAs are web apps that use service workers, manifests, and other web-platform features in combination with progressive enhancement to give users an experience on par with native apps.`,
    slug: "/services/operations",
  },
]

export default data
