import React from "react"
import { FaCode, FaCogs, FaAndroid } from "react-icons/fa"
const data = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Static Sites Generation",
    text: `Source content faster. Compile and build faster. Build the fastest websites on the web, all using static site generation.`,
    slug: "/services/static",
  },
  {
    id: 2,
    icon: <FaCogs className="service-icon" />,
    title: "Full Stack Development",
    text: `A headless, full stack system, decoupled front and back-ends, Rest and GraphQL API, source content from anywhere, Super performance`,
    slug: "/services/fullStack/",
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "Mobile Apps",
    text: `Mobile apps for IOS and Android platforms. The future is here now and your reach to the far corners of the world relised`,
    slug: "/services/mobile",
  },
  /* {
    id: 4,
    icon: <FaStarHalfAlt className="service-icon" />,
    title: "Managed Client Services",
    text: `One stop shop for management of all client IT services including domain names, hosting, email services and more!.`,
    slug: "/services/clientServices",
  }, */
]

export default data
