import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Tiles } from ".."

const SwitcherTiles = () => {
  return (
    <>
      <div>
        <StaticImage
          src="../../images/values-v1.jpg"
          alt="values"
          formats={["AUTO", "WEBP", "AVIF"]}
          placeholder="blurred"
          title="home-section-one"
          height={400}
        />
      </div>
      <Tiles />
    </>
  )
}

export default SwitcherTiles
