import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { ProjectsCard } from "../../components"

const HeadlessProjects = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiProjectCards(filter: { type: { eq: "Headless" } }) {
            nodes {
              id
              headerTitle
              time
              link
              github
              tab
              tabId
              switcher
              switcherId
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: AUTO, placeholder: BLURRED)
                  }
                }
              }
              title
              description
              category
              categoryText
              sharedStack {
                id
                title
              }
              switcher
              switcherId
            }
          }
        }
      `}
      render={data => {
        //console.log("HeadlessProjects - data:", data)

        const {
          allStrapiProjectCards: { nodes: headlessProjects },
        } = data

        //console.log("allStrapiProjectCards - static Project Cards:", Headless)

        return (
          <section className="uk-section uk-section-small  uk-padding-remove-bottom uk-padding-remove-top  uk-margin-small-bottom">
            <div className="uk-container uk-padding-remove-bottom">
              <br />
              <div uk-slider="velocity: 5">
                <div className="uk-position-relative">
                  <div className="uk-slider-container">
                    <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid uk-grid-medium">
                      {headlessProjects.map((project, index) => {
                        return (
                          <ProjectsCard
                            key={project.id}
                            index={index}
                            {...project}
                          />
                        )
                      })}
                    </ul>
                  </div>

                  <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

                  <div className="uk-hidden@m uk-light">
                    <Link
                      className="uk-position-center-left uk-position-small"
                      to="/"
                      uk-slidenav-previous="true"
                      uk-slider-item="previous"
                    >
                      {null}
                    </Link>
                    <Link
                      className="uk-position-center-right uk-position-small"
                      to="/"
                      uk-slidenav-next="true"
                      uk-slider-item="next"
                    >
                      {null}
                    </Link>
                  </div>
                  <div className="uk-visible@m">
                    <Link
                      className="uk-position-center-left-out uk-position-small"
                      to="/"
                      uk-slidenav-previous="true"
                      uk-slider-item="previous"
                    >
                      {null}
                    </Link>
                    <Link
                      className="uk-position-center-right-out uk-position-small"
                      to="/"
                      uk-slidenav-next="true"
                      uk-slider-item="next"
                    >
                      {null}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}

export default HeadlessProjects
