import React from "react"
import { FaServer } from "react-icons/fa"
import { MdEmail, MdOutlineDomainVerification } from "react-icons/md"

const data = [
  {
    id: 1,
    icon: <MdOutlineDomainVerification className="service-icon" />,
    title: "Domain Names Services",
    text: `Most of our clients have 1 or more sites and management of those domain names and reset should be a conxern for us so you can strive`,
    slug: "/services/clientServices",
  },
  {
    id: 2,
    icon: <FaServer className="service-icon" />,
    title: "Hosting Services",
    text: `Managing our clients hosting services for new or existing web applications and sites should be a concern for us so you can strive.`,
    slug: "/services/clientServices/",
  },
  {
    id: 3,
    icon: <MdEmail className="service-icon" />,
    title: "Email Services",
    text: `Managing our clients email services for new or existing businesses should be a concern for us so you can strive`,
    slug: "/services/clientServices",
  },
]

export default data
