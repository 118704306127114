import React from "react"
//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const Hero = ({ image, logo, title }) => {
  //console.log("Hero component hero image:", image)
  return (
    <section>
      <div className="uk-cover-container">
        {image && (
          <GatsbyImage
            image={image}
            style={{
              height: "55vh",
              width: "100vw",
            }}
            alt=""
          />
        )}

        <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
          <div className="uk-flex uk-flex-center">
            <div className="uk-width-3-3 uk-margin-top">
              {logo ? (
                <div className="uk-heading-medium uk-text-center uk-light uk-animation-fade uk-animation-slide-top-medium uk-margin-medium-bottom">
                  <span>
                    <GatsbyImage image={logo} alt="adudev logo" />
                  </span>
                </div>
              ) : (
                <div className="uk-heading-medium uk-heading-line uk-text-center uk-light uk-animation-fade uk-animation-slide-top-medium medium uk-margin-small-top">
                  <span>{title}</span>
                </div>
              )}

              <h2 className="uk-text-center uk-light uk-animation-fade uk-animation-slide-bottom-medium uk-margin-large-bottom">
                <span>Development and consulting services you can trust</span>
              </h2>
              <div className="uk-flex uk-flex-center uk-flex-middle">
                <span
                  className="uk-button uk-button-default uk-button-medium uk-animation-fade uk-animation-slide-left-medium uk-margin-right uk-margin-small-bottom"
                  uk-tooltip="title: Button disabled"
                >
                  News
                </span>
                {/* <Link
                  to="/articles"
                  className="uk-button uk-button-default uk-button-medium uk-animation-fade uk-animation-slide-left-medium uk-margin-right uk-margin-small-bottom"
                ></Link> */}
                <span
                  className="uk-button uk-button-default uk-button-medium uk-animation-fade uk-animation-slide-right-medium uk-margin-small-bottom"
                  uk-tooltip="title: Button disabled"
                >
                  Blogs
                </span>
                {/* <Link
                  to="/articles"
                  className="uk-button uk-button-default uk-button-medium uk-animation-fade uk-animation-slide-right-medium uk-margin-small-bottom"
                >
                  Blogs
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Hero
