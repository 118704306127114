import React from "react"
import { Link } from "gatsby"

const SwitcherBtn = Buttons => {
  return (
    <>
      <ul
        className="uk-subnav uk-subnav-pill orange-subnav uk-flex uk-flex-center"
        uk-switcher="connect: .uk-switcher; animation: uk-animation-fade"
      >
        {Buttons.buttons.map((button, index) => {
          return (
            <li key={button.title} index={index}>
              <Link className="uk-border-pill" to={button.slug}>
                {button.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default SwitcherBtn
